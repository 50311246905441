.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input {
  border-radius: 5px;
  background-color: #f0f8ff;
  border-width: 0px;
}

input.total {
  text-align: right;
}

input.itemName {
  text-align: right;
  padding-right: 10px;
}

input.usertotal {
  text-align: right;
  width: 80px;
}

input.quantity {
  text-align: right;
}

input.shares {
  text-align: center;
}

input.names {
  text-align: center;
  font-weight: 400;
}

div.money {
  position: relative;
  font-weight: "200";
  color: "lightgrey";
}

div.money input {
  padding-left: 15px;
}

div.money:after {
  position: absolute;
  left: 6px;
  top: 1px;
  content: "$";
}

div.quantity {
  position: relative;
  font-weight: "200";
  color: "lightgrey";
}

div.quantity input {
  padding-left: 15px;
}

div.quantity:after {
  position: absolute;
  left: 6px;
  top: 1px;
  content: "#";
}
